import React, { Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faXTwitter,
    faYoutube,
    faLinkedin,
    faLock,
} from "@fortawesome/free-brands-svg-icons";

import FooterLogo from "../assets/images/W-footer-logo.svg";
import Lock from "../assets/images/lock.svg";
import talropLogo from "../assets/images/svg/talrop-logo.svg";
import AppleBadge from "../assets/images/app-store.png";
import GoogleBadge from "../assets/images/google_play_badge.png";

const Footer = () => {
    const date = new Date();
    return (
        <Fragment>
            <footer>
                <div className="footer">
                    <div>
                        <Row className="bottom-border">
                            <Col
                                lg={5}
                                md={5}
                                className="bottom-border-logo-container"
                            >
                                <div className="footer-logo-box">
                                    <Link to="/" className="footer-logo">
                                        <img
                                            src={FooterLogo}
                                            alt="Footer Logo"
                                        />
                                    </Link>

                                    <div className="flag-container">
                                        <span className="border-line"></span>
                                        <span>An initiative From</span>
                                        <a
                                            className="flag-logo"
                                            href="https://talrop.com/"
                                        >
                                            <img
                                                src={talropLogo}
                                                className="flag"
                                                alt="flag"
                                            />
                                        </a>
                                    </div>
                                    {/* <p>-  Wisdom from the peak</p> */}
                                </div>
                                <p className="address">
                                    37/1993, Infra Futura Building, <br />
                                    Seaport Airport Road, Thrikkakara, <br />
                                    Ernakulam, Kerala, 682021
                                </p>
                                {/* <p>Kochi, Kerala, IN - 69152021</p> */}
                            </Col>
                            <Col lg={7} md={7} className="footer-contents">
                                <Col lg={4} md={4} sm={4}>
                                    <h6>About</h6>
                                    <ul className="footer-link">
                                        {/* <li><Link to="/">Careers</Link></li> */}
                                        {/* <li>
                                            <Link to="/about">Who we are</Link>
                                        </li> */}
                                        <li>
                                            <Link to="/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/terms">
                                                Terms and Conditions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/cancellation-refund">
                                                Cancellation and Refund
                                            </Link>
                                        </li>
                                        {/* <li>
                                            <Link to="/">Newsroom</Link>
                                        </li>
                                        <li>
                                            <Link to="/">Social Impact</Link>
                                        </li> */}
                                    </ul>
                                </Col>
                                <Col
                                    lg={4}
                                    md={4}
                                    sm={3}
                                    className="width-adjuster"
                                >
                                    <h6>Explore</h6>
                                    <ul className="footer-link">
                                        {/* <li>
                                            <Link to="/communities">
                                                Community
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/articles">Articles</Link>
                                        </li> */}
                                        <li>
                                            <Link to="/about-us">About Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact-us">
                                                Contact Us
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/careers">Careers</Link>
                                        </li>
                                        <li>
                                            <Link to="/faq">Faq</Link>
                                        </li>
                                    </ul>
                                </Col>
                                {/* <Col
                                    lg={4}
                                    md={4}
                                    sm={3}
                                    className="width-adjuster"
                                >
                                    <h6>Coming Soon</h6>
                                    <ul className="footer-link">
                                        <li>
                                            <Link
                                                to="https://apps.apple.com/us/app/wisetalkies/id6474673454"
                                                className="apple-badge"
                                            >
                                                <img
                                                    src={AppleBadge}
                                                    alt="Apple Badge"
                                                />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/"
                                                className="google-play-badge"
                                            >
                                                <img
                                                    src={GoogleBadge}
                                                    alt="Google Play Badge"
                                                />
                                            </Link>
                                        </li>
                                    </ul>
                                </Col> */}
                            </Col>
                            {/* <Col lg={2} md={2}>
                            <h6>Social</h6>
                            <ul className="footer-link">
                                <li><Link to="/"><FontAwesomeIcon icon={faXTwitter} className="icon-color twitter-color" /> Twitter</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faYoutube} className="icon-color youtube-color" /> Youtube</Link></li>
                                <li><Link to="/"><FontAwesomeIcon icon={faLinkedin} className="icon-color linkedin-color" /> LinkedIn</Link></li>
                            </ul>
                        </Col> */}
                        </Row>
                    </div>
                </div>
                <div className="bottom-footer">
                    <div>
                        <Row>
                            <Col
                                lg={9}
                                md={9}
                                sm={9}
                                xs={12}
                                className="bottom-footer-text-content"
                            >
                                <p>
                                    © {date.getFullYear()}, WiseTalkies. All
                                    rights reserved.
                                </p>
                                <p>
                                    <span>
                                        <img src={Lock} alt="Footer Lock" />
                                    </span>{" "}
                                    Secured with SSL
                                </p>
                            </Col>
                            <Col
                                lg={3}
                                md={3}
                                sm={3}
                                xs={12}
                                className="footer-privacy"
                            >
                                {/* <p className="right-txt">
                                    Privacy Policy | Terms and conditions
                                </p> */}
                                <div>
                                    <a
                                        href="https://twitter.com/wisetalkies"
                                        className="footer-social-icons"
                                    >
                                        <FontAwesomeIcon
                                            icon={faXTwitter}
                                            className="icon-color"
                                        />
                                    </a>
                                    <a
                                        href="https://youtube.com/@wisetalkies8049"
                                        className="footer-social-icons"
                                    >
                                        <FontAwesomeIcon
                                            icon={faYoutube}
                                            className="icon-color youtube-color"
                                        />
                                    </a>
                                    <a
                                        href="https://www.linkedin.com/company/wisetalkies/"
                                        className="footer-social-icons"
                                    >
                                        <FontAwesomeIcon
                                            icon={faLinkedin}
                                            className="icon-color linkedin-color"
                                        />
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </footer>
        </Fragment>
    );
};

export default Footer;
